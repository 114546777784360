<template>
  <sgv-table
    ref="docList"
    :rKey="rKey"
    :items="docs"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.deliveredAt">
          <span :class="getClassLate(item.deliveredAt)">
            {{item.deliveredAt | date}}
          </span>
          <router-link
            class="text-decoration-none px-1"
            :to="toDetail(0, item.contactId)">
            <fa icon="plus" class="text-success"></fa>
          </router-link>
        </td>
        <td v-if="hidden.code">
          {{item.code}}
          <router-link
            class="text-decoration-none px-1"
            :to="toDoc(item)">
            <fa icon="info" class="text-info"></fa>
          </router-link>
        </td>
        <td v-if="hidden.name">
          {{item.name}}
          <div v-if="item.remark">
            <small class="text-primary" >
              {{item.remark}}
            </small>
          </div>
        </td>
      </tr>
    </template>

    <router-link
      slot="action"
      v-if="$auth.hasRole(`doc:${docType}:add`)"
      :to="toDetail(0)">
      <button
        type="button"
        class="btn btn-link text-success">
        เพิ่ม
      </button>
    </router-link>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_PURCHASEORDER } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `Doc${this.$form.capitalize(this.docType)}Detail`,
      rKey: `Doc${this.$form.capitalize(this.docType)}ListPayable`,
      headers: [
        {text: 'วันที่จัดส่ง', value: 'deliveredAt'},
        {text: 'รหัส', value: 'code'},
        {text: 'รายละเอียด', value: 'name'}
      ],
      filter: {
        limit: 0,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['deliveredAt', 'code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      rFields: ['filter'],
      docs: [],
    }
  },
  apollo: {
    docs: {
      query () {
        return LIST_PURCHASEORDER(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          docType: this.docType
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      return {
        params: {
          search: v.params ? v.params.search : ''
        }
      }
    },
    toDetail (id, contactId) {
      return {
        name: this.detailView,
        params: {docId: id},
        query: {contactId}
      }
    },
    toDoc (item) {
      return {
        name: `Doc${this.$form.capitalize(item.type)}Detail`,
        params: {docId: item.id}
      }
    },
    getClassLate (deliveredAt) {
      return new Date(deliveredAt) < new Date() ? ['text-danger'] : []
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
